
import React, {useState} from 'react';

import {Button} from '../../components/Button';
import {Modal} from '../../components/Modal';

const Header = (props) => {

    const {data} = props;

    const [visible, setVisible] = useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };

    const handleModal = () => {
        setVisible(!visible);
    };

    return (
        <header className={'max-w-480 mx-auto lg:px-4 xl:px-25 mb-4.25 lg:pt-16'}>
            <div className={'flex justify-between'}>
                <div className={'flex items-center'}>
                     {data?.logotype && <div className={'xs:h-9 xl:h-auto object-contain'}><img src={data?.logotype} alt="Logo"/></div>}
                    <nav className={'lg:ml-8 xl:ml-14 2xl:ml-32.75 w-full'}>
                        <ul className={`flex items-center justify-center text-gray font-nexa-bold
                                        lg:space-x-4 lg:text-4
                                        xl:space-x-8
                                        2xl:space-x-14
                                        3xl:space-x-16.5 3xl:text-5
                                        `}>
                            {data?.menu && data?.menu.map((item) => (
                                <li className={'hover:text-red'} key={item?.link?.title}>
                                    <a href={`${item?.link?.url}`} onClick={() => scrollToSection(item?.link?.url)}>
                                        {item?.link?.title.toUpperCase()}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className={'lg:w-48 2xl:w-77.75'}>
                    <Button
                        onClick={handleModal}
                        title={data?.button?.title.toUpperCase()}
                        styles={'bg-black text-white text-5 hover:bg-white hover:text-dark-gray'}
                    />
                </div>
            </div>
            <Modal
                visible={visible}
                onClose={handleModal}
            />
        </header>
    );
};

export default Header;
