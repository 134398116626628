import React from 'react';

export const Service = (props) => {

    const {icon, title, description, changeStyle} = props;

    return (
        <div
            className={`rounded-xl ${changeStyle ? 'bg-red' : 'bg-white'} h-auto hover:bg-pink border border-light-gray font-nexa-bold group flex flex-col
                        xl:pl-10 xl:pr-22.082 xl:py-16 xl:w-138.333 xl:items-start
                        xs:px-6 xs:py-10 xs:w-full xs:items-center`}>
            <div
                className={`mb-6 flex justify-center items-center rounded-full bg-pink group-hover:bg-white 
                            xl:h-27.5 xl:w-27.5
                            xs:h-20 xs:w-20`}>
                <div>
                    <img src={icon} alt={'service'}/>
                </div>
            </div>
            <div
                className={`mb-2.5 font-nexa-bold ${changeStyle ? 'text-white group-hover:text-black' : 'text-black'} 
                            xl:text-8
                            xs:text-5`} dangerouslySetInnerHTML={{__html: title}}>
            </div>
            <div
                className={`font-raleway-regular ${changeStyle ? 'text-white group-hover:text-black' : 'text-dark-gray'}
                            xl:text-5 xl:text-start
                            xs:text-3.5 xs:text-center
                 `} dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
    );
};
