import React from 'react';

import {CircleCheck} from '../../assets/icons';

export const Benefit = (props) => {

    const {text} = props;

    return (
        <div className={'flex items-center'}>
            <div className={'mr-4'}><CircleCheck/></div>
            <div className={`font-raleway-medium text-dark-gray
                            xl:text-6
                            xs:text4
            `} dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
    );
};
