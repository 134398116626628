import React from 'react';

import Map from '../../components/Map'
import lines from '../../assets/icons/lines.svg';

const OurArea = (props) => {

    const {id, data} = props;

    return (
        <div className={'relative z-1 overflow-hidden'}>
            <img src={lines} className={'absolute -z-1 bottom-32 left-0'} alt="lines"/>
            <img src={lines} className={'absolute -z-1 bottom-32 -right-100'} alt="lines"/>

            <section id={id}
                     className={'lg:mb-32 lg:mt-30 xs:mb-15 max-w-480 mx-auto xl:px-25 md:px-4 flex flex-col z-1'}>
                <div className={`font-nexa-bold text-center 
                             lg:text-16 
                             xs:text-6 xs:mb-2.5`} dangerouslySetInnerHTML={{__html: data?.title}}></div>
                <div className={`font-raleway-regular text-dark-gray mx-auto text-center mb-10
                             lg:text-6 xl:w-284
                             xs:text-3.5 xs:w-full xs:text-center`}
                     dangerouslySetInnerHTML={{__html: data?.text}}></div>
                <Map points={data?.points}/>
            </section>
        </div>
    );
};

export default OurArea;
