import React, {useEffect, useState} from 'react';

import {Service} from '../../components/Service';

import lines from '../../assets/icons/lines.svg';

const SpacialService = (props) => {

    const {id, data} = props;

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={'relative z-1 overflow-hidden'}>
            {width >= 1024 &&
                <>
                    <img src={lines} className={`absolute -z-1 top-44 -left-64 rotate-[206deg]
                                                 3xl:h-auto
                                                 xl:h-130
                                                 lg:h-108`} alt={'lines'}/>
                    <img src={lines} className={`absolute -z-1 top-44 -right-64 rotate-[327deg]
                                                 3xl:h-auto
                                                 xl:h-130
                                                 lg:h-108`} alt={'lines'}/>
                </>
            }

            <section id={id}
                     className={'max-w-480 mx-auto xl:px-25 xs:px-4 flex flex-col z-1 lg:mb-30 xs:mb-15 md:mt-30 xs:mt-15'}>
                <div className={`font-nexa-bold text-center 
                             lg:text-16 
                             xs:text-6 xs:mb-2.5`} dangerouslySetInnerHTML={{__html: data?.title}}></div>
                <div className={`font-raleway-regular text-dark-gray mx-auto text-center
                             lg:text-6 xl:w-284
                             xs:text-3.5 xs:w-full xs:text-center`}
                     dangerouslySetInnerHTML={{__html: data?.text}}></div>
                <div className={`flex 
                            lg:space-x-7.5 lg:space-y-0 lg:mt-15 lg:flex-row 
                             xs:flex-col xs:space-x-0 xs:space-y-6 xs:mt-7.5`}>
                    {data?.services && data?.services.map((item) => (
                        <Service
                            key={item?.title}
                            icon={item?.icon}
                            title={item?.title}
                            description={item?.text}
                            changeStyle={item?.red}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
};

export default SpacialService;

