import React from 'react';

export const Button = (props) => {

    const {title, onClick, styles, type, disabled} = props;

    const defaultStyles = 'w-full rounded font-nexa-bold text-4 2xl:py-6 xs:py-4';
    const combinedStyles = styles ? `${defaultStyles} ${styles}` : defaultStyles;

    return (
        <button onClick={onClick} className={combinedStyles} type={type} disabled={disabled}>
            {title}
        </button>
    );
};
