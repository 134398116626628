import React, {createContext, useEffect, useState} from 'react';
import axios from 'axios';
import {useJsApiLoader} from '@react-google-maps/api';

import {
    AskedQuestions,
    ChooseUs,
    Footer,
    Header,
    Home,
    MobileHeader,
    OurArea,
    PricingPlan,
    Reviews,
    SpacialService
} from './blocks';
import {Marquee} from './components/Marquee';

import mobileBack from './assets/icons/mobile-background.svg';
import desktopBack from './assets/icons/background.svg';

const libraries = ['places'];

export const DataContext = createContext();

function App() {

    const [data, setData] = useState({});
    const [width, setWidth] = useState(window.innerWidth);

    const getData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        libraries,
        googleMapsApiKey: 'AIzaSyB4xAhTCLiI8CtWu_gGJWoclUMItr4TKZw'
    });

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        isLoaded && (
            <DataContext.Provider value={data}>
                <div className={`relative max-w-480 mx-auto z-20`}>
                    <div>
                        {width >= 1024 ? <Header data={data?.header}/> : <MobileHeader data={data?.header}/>}

                        <Home id={'home'} data={data?.section_promo}/>

                        <div className={`mobile-bg absolute bottom-0 right-0 bg-red 3xl:w-163.25 2xl:w-116.5 
                              -z-1
                              lg:h-full lg:w-96
                              md:h-168.75 xs:w-full
                              sm:h-146.5
                              xs:h-81.5`}
                        />
                    </div>
                </div>
                <div className={'relative z-10'}>

                    <Marquee/>

                    <img className={'absolute -top-80 left-0 w-full h-full -z-2 object-cover object-top'}
                         src={width < 1024 ? mobileBack : desktopBack}
                         alt={'background'}
                    />

                    <SpacialService id={'services'} data={data?.section_services}/>
                    <ChooseUs id={'choose'} data={data?.section_why}/>
                    <PricingPlan id={'pricing'} data={data?.section_pricing}/>
                    <Reviews id={'reviews'} data={data?.section_reviews}/>
                    <OurArea id={'area'} data={data?.section_map}/>
                    <AskedQuestions id={'faq'} data={data?.section_faq}/>
                    <Footer data={data?.footer}/>

                </div>
            </DataContext.Provider>
        )
    );
}

export default App;
