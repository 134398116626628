import React from 'react';

export const CircleCheck = (props) => {

    const {changeStyle} = props;

    return (
        <svg className={'md:h-6 md:w-6 xs:h-4 xs:w-4'} width="25" height="24" viewBox="0 0 25 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.6665" cy="12" r="12" fill={changeStyle ? "#FFFFFF" : "#282828"}/>
            <g clipPath="url(#clip0_11_598)">
                <path
                    d="M18.2051 6.16621C18.0936 6.09247 17.9689 6.04183 17.8382 6.0172C17.7075 5.99256 17.5733 5.99441 17.4432 6.02264C17.3132 6.05087 17.1899 6.10492 17.0804 6.18171C16.9709 6.2585 16.8773 6.35653 16.805 6.47018L11.1324 15.3799L8.36599 12.7734C8.26839 12.6815 8.15396 12.61 8.02925 12.5631C7.90454 12.5162 7.77199 12.4948 7.63916 12.5001C7.37089 12.5108 7.11779 12.6296 6.93553 12.8305C6.75327 13.0314 6.65677 13.2978 6.66728 13.5711C6.67779 13.8445 6.79443 14.1024 6.99155 14.2881L10.6239 17.7102C10.6239 17.7102 10.7282 17.8016 10.7764 17.8336C10.8879 17.9074 11.0126 17.9581 11.1434 17.9828C11.2741 18.0074 11.4084 18.0056 11.5384 17.9774C11.6685 17.9491 11.7918 17.8951 11.9014 17.8182C12.0109 17.7414 12.1045 17.6434 12.1768 17.5297L18.5034 7.59288C18.5758 7.47925 18.6255 7.35221 18.6496 7.21901C18.6738 7.08581 18.672 6.94906 18.6443 6.81657C18.6166 6.68409 18.5635 6.55846 18.4882 6.44687C18.4128 6.33528 18.3166 6.23991 18.2051 6.16621Z"
                    fill={changeStyle ? "#951F1D" : "#FFFFFF"}/>
            </g>
            <defs>
                <clipPath id="clip0_11_598">
                    <rect width="12" height="12" fill="white" transform="translate(6.6665 6)"/>
                </clipPath>
            </defs>
        </svg>

    );
};
