import React from 'react';

export const BurgerMenu = (props) => {

    const {isMenuOpen} = props;

    return (
        <svg className={'w-9 h-9'} width="30" height="20" viewBox="0 0 30 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.00146H28.5M1.5 10.0015H28.5M1.5 19.0015H28.5" stroke={isMenuOpen ? '#951F1D' : 'black'}
                  strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
