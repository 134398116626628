import React from 'react';

export const RightArrow = (props) => {

    const {block} = props;

    return (
        <svg className={'h-4 w-4'} width="9" height="14" viewBox="0 0 9 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M1.49365 1L7.49365 7L1.49365 13" stroke={block ? '#777777' : 'white'} strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};
