import React, { useRef, useEffect } from 'react';

export const Marquee = () => {
    const marqueeRef = useRef(null);

    useEffect(() => {
        const updateMarquee = () => {
            if (marqueeRef.current) {
                const containerWidth = marqueeRef.current.offsetWidth;
                let totalWidth = 0;

                const textWidth = marqueeRef.current.querySelector('.text').offsetWidth;
                const starWidth = marqueeRef.current.querySelector('.star').offsetWidth;
                const setWidth = textWidth + starWidth;

                while (marqueeRef.current.children.length > 2) {
                    marqueeRef.current.removeChild(marqueeRef.current.lastChild);
                }

                while (totalWidth < 2 * containerWidth) {
                    const textClone = marqueeRef.current.querySelector('.text').cloneNode(true);
                    const starClone = marqueeRef.current.querySelector('.star').cloneNode(true);
                    marqueeRef.current.appendChild(textClone);
                    marqueeRef.current.appendChild(starClone);
                    totalWidth += setWidth;
                }
            }
        };

        updateMarquee();
        window.addEventListener('resize', updateMarquee);
        return () => window.removeEventListener('resize', updateMarquee);
    }, []);

    return (
        <div className={`overflow-hidden w-full bg-dark-gray flex items-center justify-center lg:h-17 xs:h-10`}>
            <div ref={marqueeRef} className={`animate-marquee infinite w-full whitespace-nowrap`}>
                <span className={`text text-white font-nexa-bold px-4 lg:text-5 xs:text-3.5`}>
                    JOIN HYPERCONNECT NOW
                </span>
                <span className={`text-red star text-8 relative inline-block`}>
                    <span className={`absolute xs:bottom-0 lg:bottom-0.5 left-0 w-full h-full flex items-center justify-center`}>
                        *
                    </span>
                </span>
            </div>
        </div>
    );
};
