import React, {useEffect, useState} from 'react';

import {Button} from '../../components/Button';
import {Modal} from '../../components/Modal';

import lines from '../../assets/icons/lines.svg';
import reversedLines from '../../assets/icons/reversed-lines.svg';
import mobileLines from '../../assets/icons/mobile-lines.svg';

const Footer = (props) => {

    const {data} = props;

    const [visible, setVisible] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const handleModal = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className={'relative z-1 overflow-hidden'}>
            {width >= 1024 ? <>
                <img src={lines} className={`absolute -z-1 top-0 left-0 
                                                     3xl:h-auto
                                                     xl:h-130
                                                     lg:h-99.5`} alt={'lines'}/>
                <img src={reversedLines} className={`absolute -z-1 top-0 right-0 
                                                     3xl:h-auto
                                                     xl:h-130
                                                     lg:h-99.5`} alt={'lines'}/>
            </> : <img src={mobileLines} className={'absolute -z-1 top-0 left-0'} alt={'lines'}/>
            }

            <footer className={`relative overflow-hidden
                            lg:h-146.5 lg:mt-24
                            xs:h-99.5 xs:mt-15`}>
                <div
                    className={`bg-red z-20 absolute top-0 left-0 right-0 mx-auto rounded-3 text-white
                            2xl:h-116.5 2xl:w-357 2xl:px-36.5 2xl:py-25
                            xl:h-116.5 xl:w-284 xl:px-36.5 xl:py-25
                            lg:h-116.5 lg:w-211.25 lg:px-36.5 lg:py-25
                            md:h-72 md:w-138.333 md:px-6 md:py-10 
                            xs:h-81.5 xs:w-64 xs:px-6 xs:py-10 
                            `}>
                    <div className={`font-nexa-bold text-center mb-2.5
                                 xl:text-16
                                 lg:text-14
                                 md:text-10
                                 xs:text-6
                                 `} dangerouslySetInnerHTML={{__html: data?.title}}></div>
                    <div className={`font-raleway-regular text-center
                                 xl:text-6
                                 lg:text-5
                                 md:text-4
                                 xs:text-3.5
                                 `} dangerouslySetInnerHTML={{__html: data?.text}}></div>
                    <div className={`mx-auto mt-7.5
                                 lg:w-77.75
                                 xs:w-52`}>
                        <Button
                            disabled={false}
                            onClick={handleModal}
                            title={data?.button?.title.toUpperCase()}
                            styles={'bg-black text-white hover:bg-white hover:text-black lg:text-5 xs:text-4'}
                        />
                    </div>
                </div>
                <Modal
                    visible={visible}
                    onClose={handleModal}
                />
                <div className={'h-80 bg-dark-gray z-10 absolute bottom-0 left-0 right-0'}></div>
                <div className={`z-20 absolute left-0 right-0 grid place-items-center font-raleway-regular text-white
                              lg:bottom-7 lg:text-5
                              xs:bottom-5 xs:text-4`}>
                    <div className={`text-center
                                 lg:w-211 lg:text-5
                                 md:w-138.333 md:text-4
                                 xs:w-60 xs:text-3`} dangerouslySetInnerHTML={{__html: data?.copyright}}>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
