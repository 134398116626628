import React, {useState, useEffect} from 'react';

import {Button} from '../../components/Button';
import {Modal} from '../../components/Modal';

import {BurgerMenu} from '../../assets/icons';

const MobileHeader = (props) => {

    const {data} = props;

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isMenuOpen]);

    const scrollToSection = (sectionId) => {
        toggleMenu();
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };

    const handleModal = () => {
        if (isMenuOpen) {
            toggleMenu();
        }
        setVisible(!visible);
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <div className={'flex justify-between items-center px-4 py-5 border-b border-b-light-gray'}>
            <img className={`xs:h-9 object-containe`} src={data?.logotype} alt="Logo"/>
            <div onClick={toggleMenu}>
                <BurgerMenu isMenuOpen={isMenuOpen}/>
            </div>

            <div id="menu"
                 className={`border-t border-t-light-gray px-8 pb-12.5 pt-26.5 h-mobile fixed top-19 left-0 right-0 bottom-0 z-50 bg-white overflow-y-auto ${isMenuOpen ? 'visible' : 'hidden'}`}>
                <nav className={'header-list'}>
                    <ul className={'flex flex-col items-center h-full justify-between text-gray font-nexa-bold text-5 space-y-16.5'}>
                        {data?.menu && data?.menu.map((item) => (
                            <li className={'hover:text-red'} key={item?.link?.title}>
                                <a href={`${item?.link?.url}`} onClick={() => scrollToSection(item?.link?.url)}>
                                    {item?.link?.title.toUpperCase()}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
                <Button
                    onClick={handleModal}
                    title={data?.button?.title.toUpperCase()}
                    styles={'bg-black text-white hover:bg-white hover:text-black md:text-5 xs:text-4'}
                />
            </div>
            <Modal
                visible={visible}
                onClose={handleModal}
            />
        </div>
    );
};

export default MobileHeader;
