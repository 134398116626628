import React from 'react';

export const Close = () => {
    return (
        <svg className={'md:h-4.375 md:w-4.375 xs:h-3.058 xs:w-3.058 hover:cursor-pointer'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.26367 18.7384L10.002 10.0001L18.7403 18.7384M18.7403 1.26172L10.0003 10.0001L1.26367 1.26172"
                  stroke="#282828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
