import React from 'react';

export const LeftArrow = (props) => {

    const {block} = props;

    return (
        <svg className={'h-4 w-4'} width="9" height="14" viewBox="0 0 9 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50635 13L1.50635 7L7.50635 1" stroke={block ? '#777777' : 'white'} strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};
