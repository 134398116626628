import React from 'react';

const Home = (props) => {

    const {id, data} = props;

    return (
        <section id={id} className={`flex
                          lg:flex-row lg:px-4 xl:pl-25  lg:pr-0 max-w-480 mx-auto
                          xs:flex-col`}>
            <div className={`flex flex-col 
                             lg:justify-center lg:w-211.25 lg:px-0
                             xs:justify-start xs:w-full xs:px-4`}>
                <h1 className={`font-nexa-bold 
                                3xl:text-20 3xl:mb-6 3xl:mt-0
                                lg:text-12 lg:mb-6 lg:mt-0
                                xs:text-8 xs:mb-4 xs:mt-22.5`} dangerouslySetInnerHTML={{__html: data?.title}}>
                </h1>
                <div className={`text-dark-gray font-raleway-regular
                                 2xl:w-174.75 2xl:text-6
                                 lg:w-100 lg:text-4
                                 md:text-6
                                 xs:w-full xs:text-3.5`} dangerouslySetInnerHTML={{__html: data?.text}}></div>
            </div>
            <div className={'xs:mt-11.25 lg:mt-0 lg:flex lg:flex-col lg:justify-end'}>
                <img src={data?.image} alt={'main'}/>
            </div>
        </section>
    );
};

export default Home;
