export { default as PricingPlan } from './PricingPlan';
export { default as SpacialService } from './SpacialService';
export { default as ChooseUs } from './ChooseUs';
export { default as OurArea } from './OurArea';
export { default as AskedQuestions } from './AskedQuestions';
export { default as Footer } from './Footer';
export { default as Header } from './Header';
export { default as MobileHeader } from './MobileHeader';
export { default as Reviews } from './Reviews';
export { default as Home } from './Home';
