import React, {useEffect, useState} from 'react';

import {Price} from '../../components/Price';

import lines from '../../assets/icons/lines.svg';
import mobileLines from '../../assets/icons/mobile-lines.svg';

const PricingPlan = (props) => {

    const {id, data} = props;

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={'relative z-1 overflow-hidden'}>
            <img src={width >= 1024 ? lines : mobileLines}
                 className={`absolute -z-1 lg:-bottom-10 xs:bottom-0 lg:-left-40 xs:left-0 lg:-rotate-45 
                            3xl:h-auto
                            xl:h-130
                            lg:h-108`} alt={'lines'}/>
            <img src={width >= 1024 ? lines : mobileLines}
                 className={`absolute -z-1 lg:top-44 xs:top-22.5 lg:-right-64 xs:right-0 lg:rotate-[327deg] 
                            3xl:h-auto
                            xl:h-130
                            lg:h-108`}
                 alt={'lines'}/>

            <section id={id}
                     className={`lg:mb-30 lg:mt-30 xs:mb-15 max-w-480 mx-auto xl:px-25 xs:px-4 flex flex-col z-1`}>
                <div className={`font-nexa-bold text-center 
                             lg:text-16 
                             xs:text-6 xs:mb-2.5`} dangerouslySetInnerHTML={{__html: data?.title}}></div>
                <div className={`font-raleway-regular text-dark-gray mx-auto text-center
                             lg:text-6 xl:w-284
                             xs:text-3.5 xs:w-full xs:text-center`}
                     dangerouslySetInnerHTML={{__html: data?.text}}></div>
                <div className={`flex 
                             lg:flex-row lg:space-x-7.5 lg:mt-15 lg:space-y-0
                             xs:flex-col xs:space-y-7.5 xs:mt-7.5`}>
                    {data?.items && data?.items.map((item) => (
                        <Price
                            key={item?.title}
                            title={item?.title}
                            subtitle={item?.subtitle}
                            price={item?.price}
                            button={item?.button}
                            features={item?.texts}
                            changeStyle={item?.red}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
};
export default PricingPlan;

