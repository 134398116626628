import React from 'react';
import Select, {components} from 'react-select';

import {Arrow} from '../../assets/icons';

export const CustomSelect = (props) => {
    const {placeholder, options, label, required, name, error, value, onChange} = props;

    const customStyle = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "1px solid #951F1D" : "1px solid #CDCDCD",
            boxShadow: state.isFocused ? "0 0 0 1px blue" : null,
            padding: '1rem',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: 0,
        }),
        input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
        }),
        placeholder: (base) => ({
            ...base,
            color: '#CDCDCD',
            size: '1rem'
        }),
        menu: (provided, state) => ({
            ...provided,
            margin: 0,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#CDCDCD" : null,
            color: state.isSelected ? "white" : "#CDCDCD",
            padding: "0.5rem 0.875rem"
        })
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <Arrow/>
            </components.DropdownIndicator>
        );
    };

    return (
        <div className={'w-full'}>
            {label &&
                <label className={'text-dark-gray font-nexa-bold text-4 mb-3'}>
                    {label}
                    {required && <span className={'text-red-500 text-xs'}>*</span>}
                </label>
            }
            <Select
                options={options}
                required={true}
                name={name}
                placeholder={placeholder}
                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                openMenuOnFocus
                styles={customStyle}
                value={value}
                onChange={onChange}
            />
            {error && <p className="text-red-500 text-sm">Field is required</p>}
        </div>
    );
};
