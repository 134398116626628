import React, {useState} from 'react';

import {Button} from '../Button';
import {Modal} from '../Modal';

import {CircleCheck} from '../../assets/icons';

export const Price = (props) => {

    const {title, price, features, changeStyle, button, subtitle} = props;

    const [visible, setVisible] = useState(false);

    const handleModal = () => {
        setVisible(!visible);
    };

    return (
        <div
            className={`flex flex-col justify-between rounded-2xl border border-light-gray font-nexa-bold group ${changeStyle ? 'text-white bg-red price' : 'bg-white'}
                        2xl:px-15 2xl:py-20 2xl:w-138.333 2xl:h-205
                        xs:px-6 xs:py-10 xs:w-full xs:h-168.75`}>
            <div>
                <div className={`2xl:text-6 2xl:mb-10
                                 xs:text-4 xs:mb-6`} dangerouslySetInnerHTML={{__html: title}}></div>
                <div className={`flex content-center mb-10
                                 2xl:flex-row
                                 xs:flex-col`}>
                    <div
                        className={`${changeStyle && 'text-white'}
                                    2xl:text-16 2xl:mr-4
                                    xs:text-12 xs:mr-2`} dangerouslySetInnerHTML={{__html: price}}></div>
                    <div className={'flex flex-col space-x-1 justify-center'}>
                        <div className={`font-nexa-bold
                                         2xl:text-5
                                         xs:text-4`}>/Month
                        </div>
                        <div className={`font-raleway-regular
                                         2xl:text-4
                                         xs:text-3.5`} dangerouslySetInnerHTML={{__html: subtitle}}></div>
                    </div>
                </div>
                <div className={'flex content-center'}>
                    <div className={`flex flex-col space-y-5`}>
                        {features.map((i, index) => (
                            <div key={index} className={'flex items-center'}>
                                <div className={'mr-4'}><CircleCheck changeStyle={changeStyle}/></div>
                                <div
                                    className={`${changeStyle ? 'text-white' : 'text-dark-gray'} font-raleway-medium
                                                2xl:text-5
                                                xs:text-3.5`} dangerouslySetInnerHTML={{__html: i?.text}}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Button
                onClick={handleModal}
                title={button?.title.toUpperCase()}
                styles={`border ${changeStyle ? 'border-red bg-white hover:bg-black text-red hover:text-white' : 'border-dark-gray hover:border-red text-dark-gray hover:text-red'}`}
            />

            <Modal
                visible={visible}
                onClose={handleModal}
            />
        </div>
    );
};
