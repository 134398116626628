import React from 'react';

import Question from '../../components/Question';

const AskedQuestions = (props) => {

    const {id, data} = props;

    return (
        <section id={id} className={`grid grid-cols-1 xl:grid-cols-2 gap-8 max-w-480 mx-auto xl:px-25 xs:px-4 flex-col z-1`}>
            <div className={`flex flex-col
                             2xl:w-174.75 
                             xl:text-start
                             lg:content-start lg:text-center
                             xs:content-center: xs:w-full`}>
                <div className={`font-nexa-bold 
                                 lg:text-16 
                                 xs:text-6 xs:mb-2.5`} dangerouslySetInnerHTML={{__html: data?.title}}></div>
                <div className={`font-raleway-regular text-dark-gray mb-10
                             lg:text-6
                             xs:text-3.5`} dangerouslySetInnerHTML={{__html: data?.text}}></div>
            </div>
            <div className={'xs:w-full'}>
                {data?.faq && data?.faq.map((item) => (
                    <Question key={item?.answer} question={item?.question} answer={item?.answer}/>
                ))}
            </div>
        </section>
    );
};

export default AskedQuestions;
