import React from 'react';

export const Arrow = () => {
    return (
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.63804 6.62005L0.328037 1.04705C0.239392 0.954058 0.189941 0.830517 0.189941 0.702046C0.189941 0.573575 0.239392 0.450035 0.328037 0.357047L0.334037 0.351047C0.37701 0.305811 0.428736 0.269792 0.486069 0.245178C0.543402 0.220564 0.605144 0.207871 0.667537 0.207871C0.72993 0.207871 0.791671 0.220564 0.849004 0.245178C0.906337 0.269792 0.958063 0.305811 1.00104 0.351047L6.00104 5.59905L10.999 0.351047C11.042 0.305811 11.0937 0.269792 11.1511 0.245178C11.2084 0.220564 11.2701 0.207871 11.3325 0.207871C11.3949 0.207871 11.4567 0.220564 11.514 0.245178C11.5713 0.269792 11.6231 0.305811 11.666 0.351047L11.672 0.357047C11.7607 0.450035 11.8101 0.573575 11.8101 0.702046C11.8101 0.830517 11.7607 0.954058 11.672 1.04705L6.36204 6.62005C6.31534 6.66906 6.25918 6.70808 6.19695 6.73474C6.13472 6.7614 6.06773 6.77515 6.00004 6.77515C5.93234 6.77515 5.86535 6.7614 5.80312 6.73474C5.7409 6.70808 5.68473 6.66906 5.63804 6.62005Z"
                fill="black"/>
        </svg>
    );
};
