import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {LeftArrow, RightArrow} from '../../assets/icons';

const CustomDots = ({slides, currentSlide, slideCount}) => (
    <ul className={'slick-dots'} style={{bottom: '-35px'}}>
        {slides.map((slide) => (
            <li
                key={slide?.name}
                className={currentSlide === slide.id - 1 ? 'slick-active' : ''}
                style={{
                    width: '12px',
                    height: '12px',
                    backgroundColor:
                        currentSlide === slide.id - 1 ? '#951F1D' : '#CDCDCD',
                    marginRight: '5px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    cursor: 'pointer',
                }}
            />
        ))}
    </ul>
);

const Reviews = (props) => {
    const {id, data} = props;
    const [width, setWidth] = useState(window.innerWidth);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);
    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const slider = React.useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => (
            <CustomDots {...dots} slides={data?.reviews} currentSlide={currentSlide}/>
        ),
        beforeChange: (current, next) => {
            setCurrentSlide(next)
        },
    };

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePrevClick = () => {
        if (!isStart) {
            slider?.current?.slickPrev();
            if (slideIndex !== 0) {
                setSlideIndex(slideIndex - 1);
            }
        }
    };

    const handleNextClick = () => {
        if (!isEnd) {
            slider?.current?.slickNext();
            if (slideIndex !==
                slider.current.innerSlider.state.slideCount - 1) {
                setSlideIndex(slideIndex + 1);
            }
        }
    };

    useEffect(() => {
        const sliderInstance = slider.current;
        if (sliderInstance) {
            if (data?.reviews.length > 0) {
                if (sliderInstance.innerSlider.state.currentSlide === 0) {
                    setIsStart(true);
                    setSlideIndex(1)
                } else {
                    setIsStart(false);
                }

                if (
                    sliderInstance.innerSlider.state.currentSlide ===
                    sliderInstance.innerSlider.state.slideCount - 1
                ) {
                    setSlideIndex(sliderInstance.innerSlider.state.slideCount - 2)
                    setIsEnd(true);
                } else {
                    setIsEnd(false);
                }
            } else {
                setIsStart(false);
                setIsEnd(false);
            }
        }
    }, [currentSlide]);

    return (
            <section id={id} className={`grid grid-cols-1 xl:grid-cols-2 gap-8 max-w-480 mx-auto xl:px-25 xs:px-4 flex-col z-1
                          lg:mb-30 lg:mt-30
                          xs:mb-15`}>
                <div className={`flex flex-col
                             xl:content-start xl:text-start
                             xs:content-center xs:text-center xs:w-full`}>
                    <div className={`font-nexa-bold 
                                 lg:text-16 
                                 xs:text-6 xs:mb-2.5`} dangerouslySetInnerHTML={{__html: data?.title}}></div>
                    <div className={`font-raleway-regular text-dark-gray mb-10
                             lg:text-6
                             xs:text-3.5`} dangerouslySetInnerHTML={{__html: data?.text}}></div>
                </div>
                {data && data?.reviews !== undefined &&
                    <div className={'3xl:w-211.25 xl:w-177.75 xs:w-full relative'}>
                        <Slider ref={slider} {...settings}>
                            {data?.reviews && data?.reviews.map((slide) => (
                                <div
                                    className={`current-slide slide bg-white flex flex-col justify-between text-start border border-light-gray rounded-lg
                                        xs:w-191.25 xs:py-10 xs:px-6 xs:h-71.5
                                        xl:w-191.25 lg:py-10 lg:px-10 lg:h-77.25`}
                                    key={slide?.name}>
                                    <div
                                        className={'xs:text-4 lg:text-7 font-raleway-regular text-dark-gray mb-6'}
                                        dangerouslySetInnerHTML={{__html: slide?.text}}></div>
                                    <div className={'flex justify-between items-center'}>
                                        <div className={'flex flex-col '}>
                                            <div className={'text-5 font-nexa-bold text-dark-gray mb-1'}
                                                 dangerouslySetInnerHTML={{__html: slide?.name}}></div>
                                            <div
                                                className={'text-3.5 font-raleway-regular text-gray'}
                                                dangerouslySetInnerHTML={{__html: slide?.position}}></div>
                                        </div>
                                        {width >= 1024 && (
                                            <div className={'flex space-x-7.5'}>
                                                <button
                                                    disabled={isStart}
                                                    className={`${isStart ? 'bg-light-gray' : 'bg-red hover:bg-dark-gray'} flex justify-center items-center pr-1 w-11 h-11 rounded-full`}
                                                    onClick={() => {
                                                        slider?.current?.slickPrev();
                                                        handlePrevClick()
                                                    }}><LeftArrow block={isStart}/></button>
                                                <button
                                                    disabled={isEnd}
                                                    className={`${isEnd ? 'bg-light-gray' : 'bg-red hover:bg-dark-gray'} flex justify-center items-center pl-1 w-11 h-11 rounded-full`}
                                                    onClick={() => {
                                                        slider?.current?.slickNext();
                                                        handleNextClick()
                                                    }}><RightArrow block={isEnd}/></button>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            ))}
                        </Slider>
                        {data?.reviews && width >= 1024 && data?.reviews.length > 1 && (
                            <div
                                className={`slide flex flex-col bg-tiny-gray justify-between text-start border border-light-gray rounded-lg
                                        xs:w-191.25 xs:py-10 xs:px-6 xs:h-71.5
                                        lg:w-191.25 lg:py-10 lg:px-10 lg:h-77.25 lg:absolute lg:top-28 right-0 -z-1`}
                                key={data?.reviews[slideIndex]?.name}>
                                <div
                                    className={'xs:text-4 lg:text-7 font-raleway-regular text-dark-gray mb-6'}
                                    dangerouslySetInnerHTML={{__html: data?.reviews[slideIndex].text}}></div>
                                <div>
                                    <div
                                        className={'text-5 font-nexa-bold text-dark-gray mb-1'}
                                        dangerouslySetInnerHTML={{__html: data?.reviews[slideIndex].name}}></div>
                                    <div
                                        className={'text-3.5 font-raleway-regular text-gray'}
                                        dangerouslySetInnerHTML={{__html: data?.reviews[slideIndex].position}}></div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </section>
    );
};

export default Reviews;
