import React from 'react';

export const QuestionArrow = (props) => {

    const {isOpen} = props;

    return (
        <svg className={'md:h-6 md:w-6 xs:h-4 xs:w-4'} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.342245 1.75752C-0.0482763 1.36699 -0.0482904 0.733837 0.342245 0.343302C0.73278 -0.0472333 1.36594 -0.0472191 1.75646 0.343302L9.94886 8.5357L9.94885 2.46463C9.94887 1.91236 10.3966 1.46465 10.9489 1.46463C11.5011 1.46461 11.9489 1.91235 11.9488 2.46462L11.9488 10.9499C11.9488 11.5022 11.5011 11.9499 10.9488 11.9499L2.46356 11.9499C1.91131 11.9499 1.46357 11.5022 1.46357 10.9499C1.46357 10.3977 1.91131 9.94991 2.46356 9.94991L8.53464 9.94991L0.342245 1.75752Z"
                fill={isOpen ? `#951F1D` :`#282828`}/>
        </svg>
    );
};
