import React from 'react';

export const Input = (props) => {
    const {type, placeholder, value, onChange, label, required, name, error, ref} = props;

    return (
        <div className={'w-full'}>
            {label &&
                <label className={'text-dark-gray font-nexa-bold text-4 mb-3'}>
                    {label}
                    {required && <span className={'text-red-500 text-xs'}>*</span>}
                </label>
            }
            <input
                ref={ref}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`border border-light-gray focus:border-red rounded px-6 py-4 w-full font-raleway-regular text-dark-gray placeholder-light-gray ${error ? 'border-red-500' : ''}`}
                required={required}
            />
            {error && <p className="text-red-500 text-sm">Field is required</p>}
        </div>
    );
};
