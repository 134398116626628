import React from 'react';

import {Benefit} from '../../components/Benefit';

const ChooseUs = (props) => {

    const {id, data} = props;

    return (
        <section id={id} className={`grid grid-cols-1 xl:grid-cols-2 gap-8 max-w-480 mx-auto xl:px-25 xs:px-4 flex-col z-1
                          lg:mb-30 lg:mt-30
                          xs:mb-15`}>
            <div className={'xs:flex xs:items-center xs:justify-center'}>
                <img className={'md:w-177.75 lg:h-108 xs:w-full'} src={data?.image} alt={'choose-us'}/>
            </div>
            <div className={'flex flex-col content-start'}>
                <div className={`font-nexa-bold mb-2.5
                             lg:text-16 xl:text-start 
                             xs:text-6 xs:text-center`} dangerouslySetInnerHTML={{__html: data?.title}}></div>
                <div className={`font-raleway-regular text-dark-gray mx-auto mb-10
                             lg:text-6 xl:text-start
                             xs:text-3.5 xs:w-full xs:text-center`}
                     dangerouslySetInnerHTML={{__html: data?.text}}></div>
                <div className={'flex flex-col space-y-5'}>
                    {data?.benefits && data?.benefits.map((item) => (
                        <Benefit
                            key={item?.benefit}
                            text={item?.benefit}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ChooseUs;
