import React from 'react';
import {GoogleMap, Polygon} from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '685px'
};

const center = {
    lat: -33.867,
    lng: 151.2045
};


const Map = (props) => {

    const {points} = props;

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        map.setZoom(14);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    let coordinates;
    if (points) {
        coordinates = points.map(i => {
            return {lat: i.point.lat, lng: i.point.lng}
        })
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Polygon
                paths={coordinates}
                options={{
                    fillColor: "#0000",
                    fillOpacity: 0.35,
                    strokeColor: "#941f1e",
                    strokeOpacity: 0.8,
                    strokeWeight: 5,
                }}
            />
        </GoogleMap>
    )
};

export default React.memo(Map);
