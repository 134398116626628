import React, {useState} from 'react';

import {QuestionArrow} from '../../assets/icons';

const MyComponent = (props) => {

    const {question, answer} = props;

    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div onClick={toggleAccordion}
             className={`border rounded-3 border-light-gray bg-tiny-gray cursor-pointer ${isOpen && 'border-red'}
                         xl:mb-6 lg:py-5 lg:px-10
                         xs:mb-4 xs:py-4.25 xs:px-6`}>
            <div
                className={`flex justify-between items-center bg-gray-100 font-nexa-bold transition-all duration-300 ${isOpen && 'pb-6 text-red'}
                            lg:text-6
                            xs:text-4`}
            >
                <div dangerouslySetInnerHTML={{__html: question}}></div>
                <div className={`${isOpen && 'origin-center -rotate-90'}`}><QuestionArrow isOpen={isOpen}/></div>
            </div>
            <div
                className={`overflow-hidden transition-all duration-300 font-raleway-regular text-5 ${isOpen ? 'max-h-96 mb-2.5 border-t border-light-gray' : 'border-none max-h-0'}`}>
                <p className={'pt-6'} dangerouslySetInnerHTML={{__html: answer}}></p>
            </div>
        </div>
    );
};

export default MyComponent;
